.content-align {
    text-align: start;
}

.submit-btn {
    margin: 0;
    background-color: #7FDCA5 !important;
    width: 100%;
    text-align: center !important;
    color: white !important;
    border: transparent !important;
    border-radius: 50px !important;
    padding: 10px !important;
    font-family: 'Barlow', sans-serif !important;
}

.chat-img {
    width: 100%;
}

.form-group>label {
    color: var(--theme-darkgrey);
}

/* Breakpoints */
@media only screen and (max-width: 767px) {
    .content-align {
        text-align: center;
    }
}