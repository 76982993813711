@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;500;700&display=swap');

.navbar-link {
    font-size: 20px !important;
    color: var(--theme-black) !important;
    text-decoration: none;
    font-family: 'Barlow', sans-serif !important;
    font-weight: 700 !important;
}

.navbar-link:hover{
    opacity: 60%;
}

.navbar-item {
    text-decoration: none;
}

.navbar-logo {
    max-height: 40px;
}

.navbar {
    justify-content: right !important;
    padding: 20px 2% 0.25rem 2% !important;
    margin-top: 0px !important;
}

.navbar-expanded {
    justify-content: right; 
    align-items: right;
}

.navbar-list {
    justify-content: right !important;
}
