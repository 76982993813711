.home-btn{
    margin: 0;
    background-color: #7FDCA5 !important;
    width: 100%;
    text-align: center !important;
    color: white !important;
    border: transparent !important;
    border-radius: 50px !important;
    padding: 10px !important;
    font-family: 'Barlow', sans-serif !important;
    max-width: 200px;
}